import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Segment,
    Item,
    Dropdown,
    Divider,
    Button,
    Message,
} from 'semantic-ui-react';

import mindImg from '../../images/mind.svg';

import {
    CATEGORIES,
    NUM_OF_QUESTIONS,
    DIFFICULTY,
    QUESTIONS_TYPE,
    COUNTDOWN_TIME,
} from '../../constants';
import {shuffle} from '../../utils';
import results from './results.json';
import Offline from '../Offline';

const Main = ({startQuiz}) => {
    // console.log('------- ', startQuiz);
    const [category, setCategory] = useState('0');
    const [numOfQuestions, setNumOfQuestions] = useState(5);
    const [difficulty, setDifficulty] = useState('easy');
    const [questionsType, setQuestionsType] = useState('0');
    const [countdownTime, setCountdownTime] = useState({
        hours: 0,
        minutes: 240,
        seconds: 60,
    });
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [offline, setOffline] = useState(false);

    const handleTimeChange = (e, {name, value}) => {
        setCountdownTime({...countdownTime, [name]: value});
    };

    let allFieldsSelected = false;
    if (
        category &&
        numOfQuestions &&
        difficulty &&
        questionsType &&
        (countdownTime.hours || countdownTime.minutes || countdownTime.seconds)
    ) {
        allFieldsSelected = true;
    }

    const hardcodedPlay = () => {

        setProcessing(false);
        startQuiz(
            results,
            countdownTime.hours + countdownTime.minutes + countdownTime.seconds
        );

    }
    const fetchData = () => {
        setProcessing(true);

        if (error) setError(null);

        const API = `https://opentdb.com/api.php?amount=${numOfQuestions}&category=${category}&difficulty=${difficulty}&type=${questionsType}`;


        fetch(API)
            .then(respone => respone.json())
            .then(data =>
                setTimeout(() => {
                    const {response_code, results} = data;

                    if (response_code === 1) {
                        const message = (
                            <p>
                                The API doesn't have enough questions for your query. (Ex.
                                Asking for 50 Questions in a Category that only has 20.)
                                <br/>
                                <br/>
                                Please change the <strong>No. of Questions</strong>,{' '}
                                <strong>Difficulty Level</strong>, or{' '}
                                <strong>Type of Questions</strong>.
                            </p>
                        );

                        setProcessing(false);
                        setError({message});

                        return;
                    }

                    results.forEach(element => {
                        element.options = shuffle([
                            element.correct_answer,
                            ...element.incorrect_answers,
                        ]);
                    });
                    console.log(results);

                    setProcessing(false);
                    startQuiz(
                        results,
                        countdownTime.hours + countdownTime.minutes + countdownTime.seconds
                    );
                }, 1000)
            )
            .catch(error =>
                setTimeout(() => {
                    if (!navigator.onLine) {
                        setOffline(true);
                    } else {
                        setProcessing(false);
                        setError(error);
                    }
                }, 1000)
            );
    };

    //if (offline) return <Offline/>;

    return (
        <Container>
            <Segment>
                <Item.Group divided>
                    <Item>
                        {/*<Item.Image src={mindImg}/>*/}
                        <Item.Image src="/quiz-app/computer.png"/>
                        <Item.Content>
                            <Item.Header>
                                <h1>Bursdagsquizzen til Arne</h1>
                                <h3>Vinner du bursdagsgaven? Du får 4 minutter og gysla vanskelige spørsmål!</h3>
                            </Item.Header>
                            {error && (
                                <Message error onDismiss={() => setError(null)}>
                                    <Message.Header>Error!</Message.Header>
                                    {error.message}
                                </Message>
                            )}
                            {/*<Divider />*/}
                            {/*<Item.Meta>*/}
                            {/*  <p>In which category do you want to play the quiz?</p>*/}
                            {/*  <Dropdown*/}
                            {/*    fluid*/}
                            {/*    selection*/}
                            {/*    name="category"*/}
                            {/*    placeholder="Select Quiz Category"*/}
                            {/*    header="Select Quiz Category"*/}
                            {/*    options={CATEGORIES}*/}
                            {/*    value={category}*/}
                            {/*    onChange={(e, { value }) => setCategory(value)}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*  <br />*/}
                            {/*  <p>How many questions do you want in your quiz?</p>*/}
                            {/*  <Dropdown*/}
                            {/*    fluid*/}
                            {/*    selection*/}
                            {/*    name="numOfQ"*/}
                            {/*    placeholder="Select No. of Questions"*/}
                            {/*    header="Select No. of Questions"*/}
                            {/*    options={NUM_OF_QUESTIONS}*/}
                            {/*    value={numOfQuestions}*/}
                            {/*    onChange={(e, { value }) => setNumOfQuestions(value)}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*  <br />*/}
                            {/*  <p>How difficult do you want your quiz to be?</p>*/}
                            {/*  <Dropdown*/}
                            {/*    fluid*/}
                            {/*    selection*/}
                            {/*    name="difficulty"*/}
                            {/*    placeholder="Select Difficulty Level"*/}
                            {/*    header="Select Difficulty Level"*/}
                            {/*    options={DIFFICULTY}*/}
                            {/*    value={difficulty}*/}
                            {/*    onChange={(e, { value }) => setDifficulty(value)}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*  <br />*/}
                            {/*  <p>Which type of questions do you want in your quiz?</p>*/}
                            {/*  <Dropdown*/}
                            {/*    fluid*/}
                            {/*    selection*/}
                            {/*    name="type"*/}
                            {/*    placeholder="Select Questions Type"*/}
                            {/*    header="Select Questions Type"*/}
                            {/*    options={QUESTIONS_TYPE}*/}
                            {/*    value={questionsType}*/}
                            {/*    onChange={(e, { value }) => setQuestionsType(value)}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*  <br />*/}
                            {/*  <p>Please select the countdown time for your quiz.</p>*/}
                            {/*  <Dropdown*/}
                            {/*    search*/}
                            {/*    selection*/}
                            {/*    name="hours"*/}
                            {/*    placeholder="Select Hours"*/}
                            {/*    header="Select Hours"*/}
                            {/*    options={COUNTDOWN_TIME.hours}*/}
                            {/*    value={countdownTime.hours}*/}
                            {/*    onChange={handleTimeChange}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*  <Dropdown*/}
                            {/*    search*/}
                            {/*    selection*/}
                            {/*    name="minutes"*/}
                            {/*    placeholder="Select Minutes"*/}
                            {/*    header="Select Minutes"*/}
                            {/*    options={COUNTDOWN_TIME.minutes}*/}
                            {/*    value={countdownTime.minutes}*/}
                            {/*    onChange={handleTimeChange}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*  <Dropdown*/}
                            {/*    search*/}
                            {/*    selection*/}
                            {/*    name="seconds"*/}
                            {/*    placeholder="Select Seconds"*/}
                            {/*    header="Select Seconds"*/}
                            {/*    options={COUNTDOWN_TIME.seconds}*/}
                            {/*    value={countdownTime.seconds}*/}
                            {/*    onChange={handleTimeChange}*/}
                            {/*    disabled={processing}*/}
                            {/*  />*/}
                            {/*</Item.Meta>*/}
                            <Divider/>
                            <Item.Extra>
                                <Button
                                    primary
                                    size="big"
                                    icon="play"
                                    labelPosition="left"
                                    content={processing ? 'Vent...' : 'Sett i gang'}
                                    onClick={hardcodedPlay}
                                    disabled={!allFieldsSelected || processing}
                                />
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <br/>
        </Container>
    );
};

Main.propTypes = {
    startQuiz: PropTypes.func.isRequired,
};

export default Main;
