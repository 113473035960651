import React, {useState} from 'react';

import Layout from '../Layout';
import Loader from '../Loader';
import Main from '../Main';
import Quiz from '../Quiz';
import Result from '../Result';

import {shuffle} from '../../utils';

const App = () => {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [data, setData] = useState(null);
    const [ime, setime] = useState(null);
    const [isQuizStarted, setIsQuizStarted] = useState(false);
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);
    const [resultData, setResultData] = useState(null);

    const startQuiz = (data, ime) => {
        setLoading(true);
        setLoadingMessage({
            title: 'Vent litt...',
            message: "Kobler til stormaskinmiljø ... ",
        });
        setime(ime);



        setTimeout(() => {

            setLoadingMessage({
                title: 'Vent litt...',
                message: "Feil i COBOL-programkode, linje 483, kolonne 23",
            });


            setTimeout(() => {

                setLoadingMessage({
                    title: 'Vent litt...',
                    message: "Defragmenterer disk. Dette kan ta opptil 4 timer siden du ikke har SCSI.",
                });


                    setTimeout(() => {

                        setLoadingMessage({
                            title: 'Vent litt til... nesten der',
                            message: "Uææh, nå virker jo ingenting..",
                        });

                        setTimeout(() => {

                            setLoadingMessage({
                                title: 'Fant riktige spørsmål på platelager 00001-2034',
                                message: "Utfører omforming av symboler fra EBCDIC til ASCII",
                            });



                            setTimeout(() => {

                                setLoadingMessage({
                                    title: 'Endelig!',
                                    message: "Er du godt forberedt??",
                                });


                                setTimeout(() => {
                                    setData(data);
                                    setIsQuizStarted(true);
                                    setLoading(false);

                                }, 4000);

                            }, 4000);
                        }, 6000);
                    }, 5000);
                }, 4000);
            }, 4000);

    };

    const endQuiz = resultData => {
        setLoading(true);
        setLoadingMessage({
            title: 'Laster inn resultater...',
            message: 'Vær tålmodig!',
        });

        setTimeout(() => {
            setIsQuizStarted(false);
            setIsQuizCompleted(true);
            setResultData(resultData);
            setLoading(false);
        }, 2000);
    };

    const replayQuiz = () => {
        setLoading(true);
        setLoadingMessage({
            title: 'Da prøver vi igjen',
            message: "Noen andre bruker platelager på stormaskinmiljø. Vent litt.",
        });

        const shuffledData = shuffle(data);
        shuffledData.forEach(element => {
            element.options = shuffle(element.options);
        });

        setData(shuffledData);

        setTimeout(() => {
            setIsQuizStarted(true);
            setIsQuizCompleted(false);
            setResultData(null);
            setLoading(false);
        }, 6000);
    };

    const resetQuiz = () => {
        setLoading(true);
        setLoadingMessage({
            title: 'Laster første side igjen.',
            message: 'Fin bursdag så langt?!',
        });

        setTimeout(() => {
            setData(null);
            setime(null);
            setIsQuizStarted(false);
            setIsQuizCompleted(false);
            setResultData(null);
            setLoading(false);
        }, 1000);
    };

    return (
        <Layout>

            {loading && <Loader {...loadingMessage} />}
            {!loading && !isQuizStarted && !isQuizCompleted && (
                <Main startQuiz={startQuiz}/>
            )}
            {!loading && isQuizStarted && (
                <Quiz data={data} ime={ime} endQuiz={endQuiz}/>
            )}
            {!loading && isQuizCompleted && (
                <Result {...resultData} replayQuiz={replayQuiz} resetQuiz={resetQuiz}/>
            )}
        </Layout>
    );
};

export default App;
