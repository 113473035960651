import React from 'react';
import PropTypes from 'prop-types';
import {Segment, Header, Button} from 'semantic-ui-react';

import ShareButton from '../ShareButton';
import {calculateScore, calculateGrade, timeConverter} from '../../utils';

const Stats = ({
                   totalQuestions,
                   correctAnswers,
                   timeTaken,
                   replayQuiz,
                   resetQuiz,
               }) => {
    const score = calculateScore(totalQuestions, correctAnswers);
    const {grade, remarks} = calculateGrade(score);
    const {hours, minutes, seconds} = timeConverter(timeTaken);


    return (
        <Segment>
            <Header as="h1" textAlign="center" block>
                {remarks}
                <br/>
                {score >= 90 && (

                    <center><img src={'quiz-app/gratulerer.png'} style={{width: "100%"}}/></center>
                )
                }

            </Header>
            <Header as="h2" textAlign="center" block>
                Karakter: {grade}
            </Header>
            <Header as="h3" textAlign="center" block>
                Antall spørsmål: {totalQuestions}
            </Header>
            <Header as="h3" textAlign="center" block>
                Korrekte svar: {correctAnswers}
            </Header>
            <Header as="h3" textAlign="center" block>
                Din score: {score}%
            </Header>
            {/*<Header as="h3" textAlign="center" block>*/}
            {/*  Minimum poengsum for å bestå: 60%*/}
            {/*</Header>*/}
            {/*<Header as="h3" textAlign="center" block>*/}
            {/*  Time Taken:{' '}*/}
            {/*  {`${Number(hours)}h ${Number(minutes)}m ${Number(seconds)}s`}*/}
            {/*</Header>*/}
            {score < 90 && (
            <div style={{marginTop: 35}}>
                <Button
                    primary
                    content="Prøv samme quiz igjen"
                    onClick={replayQuiz}
                    size="big"
                    icon="redo"
                    labelPosition="left"
                    style={{marginRight: 15, marginBottom: 8}}
                />
                {/*<Button*/}
                {/*  color="teal"*/}
                {/*  content="Back to Home"*/}
                {/*  onClick={resetQuiz}*/}
                {/*  size="big"*/}
                {/*  icon="home"*/}
                {/*  labelPosition="left"*/}
                {/*  style={{ marginBottom: 8 }}*/}
                {/*/>*/}
                {/*<ShareButton />*/}
            </div>
                )}
        </Segment>
    );
};

Stats.propTypes = {
    totalQuestions: PropTypes.number.isRequired,
    correctAnswers: PropTypes.number.isRequired,
    timeTaken: PropTypes.number.isRequired,
    replayQuiz: PropTypes.func.isRequired,
    resetQuiz: PropTypes.func.isRequired,
};

export default Stats;
